<template>
  <div>
    <h2 class="text-4xl text-center font-semibold my-4">404</h2>
    <p class="text-bold text-xl text-center">The page you are looking for does not exist.</p>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = `404 Not Found`
  }
}
</script>